body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #020F25;
  color: #fff;
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
  position: relative;
  &.menu-active{
    overflow: hidden;
    &:before{
      display: block;
    }
  }
  &:before{
    content: "";
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    z-index: 990;
    background-color: rgba(0, 0, 0, 0.5);
  }
  a,a:hover{
    color: #fff;
    text-decoration: none;

  }
  footer{
    margin-top: auto;
  }
  ul,ol{
    list-style: none;
    padding: 0;
    margin: 0;
  }
}
a:active, a:focus { outline: none; }

input, textarea {outline:none;}
input:active, textarea:active {outline:none;}
:focus {outline:none;}
textarea {resize:none;}
textarea {resize:vertical;}
textarea {resize:horizontal;}
h1,h2{
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: -0.015em;
  font-family: 'Roboto Condensed', sans-serif;
}
h1{

  font-size: 144px;
  line-height: 169px;
}
h2{
  font-size: 72px;
  line-height: 84px;
}
.roboto-condensed{
  font-family: 'Roboto Condensed', sans-serif;
  &.bold{
    font-weight: bold;
  }
  &.upper{
    text-transform: uppercase;
  }
}
.bold{
  font-weight: bold!important;
}
.btn-radius{
  border: 1px solid #0071FF;
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 24px;
  font-family: Roboto,sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  &.btn-small{
    font-family: 'Roboto Condensed',sans-serif;
    font-size: 16px!important;
    line-height: 19px;
    color: #0071FF!important;
    text-transform: unset;
    padding: 13px 41px;
  }
  &.btn-white{
    background-color: #fff;
    border-color: #fff;
    color: #000;
    font-family: 'Roboto Condensed',sans-serif;
  }
}
.link{

}
@media screen and (max-width: 1659px){

}
@media screen and (max-width: 1199px){

}
@media screen and (max-width: 991px){
  h2{
    font-size: 60px;
    line-height: 72px;
  }
}
@media screen and (max-width: 767px){
  h2{
    font-size: 40px;
    line-height: 52px;
  }
}
@media screen and (max-width: 575px){
  h2{
    font-size: 30px;
    line-height: 42px;
  }
}


@import "header.scss";
@import "footer.scss";
@import "blocks/first-screen.scss";
@import "blocks/partners.scss";
@import "blocks/products.scss";
@import "blocks/projects.scss";
@import "blocks/order.scss";
@import "blocks/slider-detail.scss";

@import "modals.scss";
@import "right-menu.scss";

