.order{
  background-color:  #0071FF;
  padding: 90px 0 90px;
  h2{
    font-weight: bold;
    font-size: 44px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
  }
  .btn-radius{
   background-color: #fff;
    color: #000;
    width: 289px;
    border: 2px solid #fff;
    transition: ease .4s;
    &:hover{
      background-color: transparent;
      color: #fff;
    }
  }
}
@media screen and (max-width: 1659px){

}
@media screen and (max-width: 1199px){

}
@media screen and (max-width: 992px){

}
@media screen and (max-width: 767px){
  .order{
    background-color:  #0071FF;
    padding: 60px 0 60px;
    h2{
      font-weight: bold;
      font-size: 36px;
      line-height: 28px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 30px;
    }
    .btn-radius{
      background-color: #fff;
      color: #000;
      width: 417px;
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 575px){
  .order{
    background-color:  #0071FF;
    padding: 60px 0 60px;
    h2{
      font-weight: bold;
      font-size: 24px;
      line-height: 16px;
      letter-spacing: -0.015em;
      color: #FFFFFF;
      text-align: center;
      margin-bottom: 30px;
    }
    .btn-radius{
      background-color: #fff;
      color: #000;
      width: 100%;

    }
  }
}
