.products{
  padding: 50px 0;
  h2{
    margin: 30px 0;
  }
  .products-container{
    .product{
      margin-bottom: 20px;
      background-color: rgba(0, 113, 255, 0.1);
      padding: 25px;
      height: calc(100% - 20px);
      display: flex;
      &.pt-big{
        padding-top: 55px;
      }
      .product-img-and-link{

        width: calc(40% + 25px);
        margin-left: -25px;
        .product-img{
          margin: 0 auto 90px auto;
          width: 176px;
          height: 176px;
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        .product-link{
          width: 61px;
          height: 61px;
          position: relative;
          margin: 0 auto 23px auto;
          cursor: pointer;
          img{
            position: absolute;
            left: 0;
            top:0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            opacity: 1;
            transition: ease .4s;
            &.hovered{
              opacity: 0;
            }
          }
        }
      }
      .product-info{
        width: 60%;
        .product-name{
          font-size: 40px;
          line-height: 47px;
          text-decoration: underline;
          margin-bottom: 20px;
          color: #fff;
          transition: ease .4s;
          height: 94px;
          &.big-long{
            height:190px;
          }
          &.very-big-long{
            height:240px;
          }
          &:hover{
            color: #007bff;
          }
        }
        .product-type{
          margin-bottom: 6px;
          color: #0071FF;
          height: 48px;
        }
        .product-desc{
          font-size: 18px;
          line-height: 28px;
          p{
            margin-bottom: 20px;
            margin-top: 0;
          }
        }
      }
      &:hover{
        .product-link{
          img{
            opacity: 0;
            &.hovered{
              opacity: 1;
            }
          }
        }
        .product-info{
          .product-name{
            color: #007bff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1659px){
  .products{
    .products-container{
      .product{
        padding: 20px;
        height: calc(100% - 20px);
        &.pt-big{
          padding-top: 20px;
        }
        .product-img-and-link{

          width: calc(40% + 20px);
          margin-left: -20px;
          .product-img{
            margin: 0 auto 90px auto;
            width: 130px;
            height: 130px;
          }
          .product-link{
            width: 45px;
            height: 45px;
            position: relative;
            margin: 0 auto 23px auto;
          }
        }
        .product-info{
          .product-name{
            font-size: 26px;
            line-height: 30px;
            height: 74px;
            &.big-long{
              height:100px;
              br{
                display: none;
              }
            }
            &.very-big-long{
              height:140px;
              br{
                display: none;
              }
            }
          }
          .product-type{
            margin-bottom: 6px;
          }
          .product-desc{
            font-size: 16px;
            line-height: 26px;
            br{
              display: none;
            }
            p{
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px){
  .products{
    .products-container{
      .product{
        padding: 20px;
        height: calc(100% - 20px);
        &.pt-big{
          padding-top: 20px;
        }
        .product-img-and-link{

          width: calc(40% + 20px);
          margin-left: -20px;
          .product-img{
            margin: 0 auto 90px auto;
            width: 100px;
            height: 100px;
          }
          .product-link{
            width: 30px;
            height: 30px;
            position: relative;
            margin: 0 auto 23px auto;
          }
        }
        .product-info{
          .product-name{
            font-size: 22px;
            line-height: 29px;
            height: 58px;
          }
          .product-type{
            margin-bottom: 6px;
          }
          .product-desc{
            font-size: 14px;
            line-height: 24px;
            br{
              display: none;
            }
            p{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px){
  .products{

  }
}
@media screen and (max-width: 767px){
  .products{
      .products-container{
        .product{
          padding: 20px;
          height: calc(100% - 20px);
          &.pt-big{
            padding-top: 20px;
          }
          .product-img-and-link{

            width: calc(40% + 20px);
            margin-left: -20px;
            .product-img{
              margin: 0 auto 90px auto;
              width: 100px;
              height: 100px;
            }
            .product-link{
              width: 30px;
              height: 30px;
              position: relative;
              margin: 0 auto 23px auto;
            }
          }
          .product-info{
            .product-name{
              font-size: 22px;
              line-height: 29px;
            }
            .product-type{
              margin-bottom: 6px;
            }
            .product-desc{
              font-size: 14px;
              line-height: 24px;
              br{
                display: none;
              }
              p{
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
}
@media screen and (max-width: 575px){
  .products{
    .products-container{
      .product{
        padding: 15px;
        height: calc(100% - 15px);
        position: relative;
        &.pt-big{
          padding-top: 15px;
        }
        .product-img-and-link{
          margin-left: 0;
          width: 0;
          .product-img{
            margin: 0;
            width: 80px;
            height: 80px;
            position: absolute;
            right: 15px;
            top: 15px;
          }
          .product-link{
            width: 50px;
            height: 50px;
            position: unset;
            margin: 0 auto 23px auto;
            img{
              width: 50px;
              height: 50px;
              bottom:15px;
              right: 15px;
              left: unset;
              top: unset;
            }
          }
        }
        .product-info{
          width: 80%;
          .product-name{
            font-size: 18px;
            line-height: 25px;
            height: 50px;
          }
          .product-type{
            margin-bottom: 6px;
          }
          .product-desc{
            font-size: 14px;
            line-height: 24px;
            br{
              display: none;
            }
            p{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
