.fixed-header{
  position: fixed;
  width: 100vw;
  top: -107px;
  left:0;
  right: 0;
  transition: ease .4s;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  padding: 10px 0 0px 0!important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  nav{
    ul {
      display: flex;
      li {
        margin: 0 20px 0;
        position: relative;
        padding-bottom: 20px;
        padding-top: 5px;
        transition-timing-function:ease;
        transition-duration: .3s;
        margin-top: -100px!important;
      }
    }
  }
  &.active{
    top: 0;
    nav{
      ul {
        li {
          margin-top: 0!important;
        }
      }
    }
  }
}
header,.fixed-header{
  padding: 20px 0;
  .logo{
    padding-bottom: 20px;
    display: block;
    a{
      img{

      }
    }
  }
  nav{
    ul{
      display: flex;
      li{
        margin:0 20px;
        position: relative;
        padding-bottom: 20px;
        padding-top: 5px;
        &>a{
          font-size: 16px;
          line-height: 19px;
          letter-spacing: -0.015em;
          position: relative;
          padding: 0 5px;
          display: inline-block;
          &:hover{
            color: #0071FF;
            transition: ease .4s;
          }
          &::before{
            content: '';
            position: absolute;
            display: block;
            height: 3px;
            width: 0px;
            background-color: #0071FF;
            right: 0;
            bottom: -20px;
            left: unset;
            transition: ease .4s;
          }
           &.active{
            &::before{
              width: 100%;
              right: unset;
              left: 0;
            }
          }
          &.reverse{
            &::before{
              content: '';
              position: absolute;
              display: block;
              height: 3px;
              width: 0px;
              background-color: #0071FF;
              right: unset;
              bottom: -20px;
              left: 0;
              transition: ease .4s;
            }
            &.active{
              &::before{
                width: 100%;
                right: 0;
                left: unset;
              }
            }
          }
        }
        .child{
          transition: ease .4s;
          overflow: hidden;
          position: absolute;
          height: 0;
          top: calc(100% - 5px);
          width: 355px;
          background-color: #fff;
          z-index: 20;
          ul{
            display: block;
            padding: 25px 40px;
            li{
              margin: 0 0 20px 0;
              padding: 0;
              a{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.015em;
                color: rgba(0, 0, 0, 0.8);
                &::before{
                  display: none;
                }
              }
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
        &.opacity-0{
          opacity:0;
        }
      }
    }
  }
  .btn-radius{
    display: block;
    padding-bottom: 15px;
    margin-top: -16px;
    transition: ease .4s;
    &:hover{
      background-color: #0071ff;
      color: #fff!important;
    }
  }
  .container-burger{
    .icon-burger{
      display: block;
      .icon-block{
        background-color: #fff;
        width: 40px;
        height: 4px;
        border-radius: 3px;
        &:nth-child(2){
          margin: 10px 0;
        }
      }

    }
  }
}
@media screen and (max-width: 1659px){
  header,.fixed-header{
  nav{
    ul{
      li{
        margin:0 10px;
      }
    }
  }
}
}
@media screen and (max-width: 1199px){
  header,.fixed-header{
    nav{
      ul{
        li{
          margin:0 10px;
          a{
            font-size: 14px;
          }
        }
      }
    }
    .btn-radius.btn-small{
      padding: 13px 20px;
    }
  }
}
@media screen and (max-width: 991px){
  header,.fixed-header{
    nav{
      ul{
        width: 100%;
        justify-content: space-between;
      }
        li{
          margin:0 auto;
          a{
            font-size: 14px;
          }
        }
      }
    }
}
@media screen and (max-width: 767px){

}
@media screen and (max-width: 575px){
  header,.fixed-header{
    .logo{
      padding-bottom: 0;
    }
  }
}
