.first-screen{
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  .btn-radius{
    transition: ease .4s;
    &:hover{
      background-color: #007bff;
    }
  }
  h1{
    position: relative;
    font-size: 144px;
    line-height: 169px;
    z-index: 70;
  }
  .text-first{
    z-index: 70;
    position: relative;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-top: 10px;
    margin-bottom: 100px;
  }
  .container-img{
    position: absolute;
    right: 200px;
    top: 15px;
    z-index: 60;
    display: none;
  }
  .circles{
    position: absolute;
    right: 200px;
    top: 15px;
    width: 784px;
    height: 722px;
    z-index: 50;
    .container-circles-main{
      position: relative;
      width: 100%;
      height: 100%;
      .big-circles{
        width: inherit;
        height: inherit;
        position: absolute;
        transform-origin: 50% 50%;
        animation: cbc 80s ease-in-out infinite;
        .container-big-circles{
          position: relative;
          img{
            position: absolute;
            transform-origin: 50%;
            display: block;
            object-fit: contain;
            object-position: center;
            width: 108px;
            height: 108px;
            &.big-circle-1{
              top: 168px;
              left: 100px;
              animation: bc-1 40s ease-in-out infinite;
            }
            &.big-circle-2{
              top: 91px;
              left: 338px;
              animation: bc-2  40s ease-in-out infinite;
            }
            &.big-circle-3{
              top: 168px;
              left: 576px;
              animation: bc-3  40s ease-in-out infinite;
            }
            &.big-circle-4{
              top: 445px;
              left: 576px;
              animation: bc-4  40s ease-in-out infinite;
            }
            &.big-circle-5{
              top: 522px;
              left: 338px;
              animation: bc-5  40s ease-in-out infinite;
            }
            &.big-circle-6{
              top: 445px;
              left: 100px;
              animation: bc-6  40s ease-in-out infinite;
            }
          }
        }
      }
      .small-circles {
        position: absolute;
        width: inherit;
        height: inherit;
        .container-small-circles{
          position: absolute;
          width: inherit;
          height: inherit;
          img {
            position: absolute;
            &.small-circle-1 {
              top: 153px;
              left: 53px;
              animation: sc-1  40s ease-in-out infinite;
            }

            &.small-circle-2 {
              top: 29px;
              left: 368.5px;
              animation: sc-2  40s ease-in-out infinite;
            }

            &.small-circle-3 {
              top: 153px;
              left: 684px;
              animation: sc-3  40s ease-in-out infinite;
            }

            &.small-circle-4 {
              top: 521px;
              left: 684px;
              animation: sc-4  40s ease-in-out infinite;
            }

            &.small-circle-5 {
              top: 645px;
              left: 368.5px;
              animation: sc-5  40s ease-in-out infinite;
            }

            &.small-circle-6 {
              top: 521px;
              left: 53px;
              animation: sc-6  40s ease-in-out infinite;
            }
          }
        }
      }
      .between-circles {
        position: absolute;
        width: inherit;
        height: inherit;
        transform-origin: 50% 50%;
        animation: cbw 80s ease-in-out infinite;
        .container-between-circles{
          position: absolute;
          width: inherit;
          height: inherit;
          img {
            position: absolute;
            &.between-circle-1 {
              top: 91px;
              left: 220.5px;
              animation: bwc-1  40s ease-in-out infinite;
            }

            &.between-circle-2 {
              top: 91px;
              left: 516.5px;
              animation: bwc-2  40s ease-in-out infinite;
            }

            &.between-circle-3 {
              top: 337px;
              left: 637px;
              animation: bwc-3  40s ease-in-out infinite;
            }

            &.between-circle-4 {
              top: 583px;
              left: 516.5px;
              animation: bwc-4  40s ease-in-out infinite;
            }

            &.between-circle-5 {
              top: 583px;
              left: 220.5px;
              animation: bwc-5  40s ease-in-out infinite;
            }

            &.between-circle-6 {
              top: 337px;
              left: 100px;
              animation: bwc-6  40s ease-in-out infinite;
            }
          }
        }
      }
      .external-circles {
        position: absolute;
        width: inherit;
        height: inherit;
        transform-origin: 50% 50%;
        animation: cec 80s ease-in-out infinite;
        .container-external-circles{
          position: absolute;
          width: inherit;
          height: inherit;
          img {
            position: absolute;
            &.external-circle-1 {
              top: 5.5px;
              left: 173.5px;
            }

            &.external-circle-2 {
              top: 5.5px;
              left: 563.5px;
            }

            &.external-circle-3 {
              top: 337px;
              left: 737px;
            }

            &.external-circle-4 {
              top: 668px;
              left: 563.5px;
            }

            &.external-circle-5 {
              top: 668px;
              left: 173.5px;
            }

            &.external-circle-6 {
              top: 337px;
              left: 0px;
            }
          }
        }
      }
    }
  }
}
@keyframes bwc-1{
  0% {
    transform: translate(0, 0);
  }

  30%{
    transform: translate(50.25px,75px);
  }
  50%{
    transform: translate(50.25px,75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes bwc-2 {
  0%{
    transform: translate(0,0);
  }
  30%{
    transform: translate(-50.25px,75px);
  }
  50%{
    transform: translate(-50.25px,75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bwc-3 {
  0%{
    transform: translate(0,0);
  }
  30%{
    transform: translate(-50.25px,0px);
  }
  50%{
    transform: translate(-50.25px,0px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bwc-4{
  0% {
    transform: translate(0, 0);
  }

  30%{
    transform: translate(-50.25px,-75px);
  }
  50%{
    transform: translate(-50.25px,-75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes bwc-5{
  0% {
    transform: translate(0, 0);
  }

  30%{
    transform: translate(50.25px,-75px);
  }
  50%{
    transform: translate(50.25px,-75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes bwc-6 {
  0%{
    transform: translate(0,0);
  }
  30%{
    transform: translate(50.25px,0px);
  }
  50%{
    transform: translate(50.25px,0px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}

@keyframes sc-1{
  0% {
    transform: translate(0, 0);
  }

  30%{
    transform: translate(81px,50.25px);
  }
  50%{
    transform: translate(81px,50.25px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes sc-2 {
  0%{
    transform: translate(0,0);
  }
  30%{
    transform: translate(0,63.75px);
  }
  31%{
    transform: translate(0,63.75px);
  }
  35%{
    transform: translate(0,43px);
  }
  47%{
    transform: translate(0px,63.75px);
  }
  50%{
    transform: translate(0,63.75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes sc-3 {
  0% {
    transform: translate(0, 0);
  }

  30%{
    transform: translate(-81px,50.25px);
  }
  50%{
    transform: translate(-81px,50.25px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes sc-4 {
  0% {

    transform: translate(0, 0);
  }
  30%{
    transform: translate(-81px,-50.25px);
  }
  50%{
    transform: translate(-81px,-50.25px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes sc-5 {
  0%{

    transform: translate(0,0);

  }
  30%{
    transform: translate(0px,-63.75px);
  }
  31%{
    transform: translate(0,-63.75px);
  }
  35%{
    transform: translate(0,-43px);
  }
  47%{
    transform: translate(0px,-63.75px);
  }
  50%{
    transform: translate(0px,-63.75px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes sc-6 {
  0%{

    transform: translate(0,0);

  }
  30%{
    transform: translate(81px,-50.25px);
  }
  50%{
    transform: translate(81px,-50.25px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}

@keyframes bc-1 {
  0% {
    transform: translate(0, 0);
  }

  25%{
    transform: translate(108px,67px);
   }
  50%{
    transform: translate(108px,67px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);
  }
}
@keyframes bc-2 {
  0%{
    transform: translate(0,0);
  }
  25%{
    transform: translate(0,85px);
  }
  50%{
    transform: translate(0,85px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bc-3 {
  0% {
    transform: translate(0, 0);
  }

  25%{
    transform: translate(-108px,67px);
  }
  50%{
    transform: translate(-108px,67px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bc-4 {
  0% {

    transform: translate(0, 0);
  }
  25%{
    transform: translate(-108px,-67px);
  }
  50%{
    transform: translate(-108px,-67px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bc-5 {
  0%{

    transform: translate(0,0);

  }
  25%{
    transform: translate(0px,-85px);
  }
  50%{
    transform: translate(0px,-85px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes bc-6 {
  0%{

    transform: translate(0,0);

  }
  25%{
    transform: translate(108px,-67px);
  }
  50%{
    transform: translate(108px,-67px);
  }
  75%{
    transform: translate(0,0);
  }
  100%{
    transform: translate(0,0);

  }
}
@keyframes cbc {
0%{
  transform: rotate(0deg);
}
  12.5%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(180deg);
  }
  62.5%{
    transform: rotate(180deg);
  }
  75%{
    transform: rotate(360deg);
  }

100%{
   transform: rotate(360deg);
}
}
@keyframes cbw {
  0%{
    transform: rotate(0deg);
  }
  12%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(180deg);
  }
  62%{
    transform: rotate(180deg);
  }
  75%{
    transform: rotate(360deg);
  }

  100%{
    transform: rotate(360deg);
  }
}
@keyframes cec {
  0%{
    transform: rotate(0deg);
  }
  8.5%{
    transform: rotate(0deg);
  }
  21%{
    transform: rotate(180deg);
  }
  58%{
    transform: rotate(180deg);
  }
  71%{
    transform: rotate(360deg);
  }

  100%{
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1659px){
  .first-screen{
    padding-top: 90px;
    padding-bottom: 90px;
    h1{

      font-size: 100px;
      line-height: 125px;
    }
    .text-first{
      margin-bottom: 70px;
    }
  }
}
@media screen and (max-width: 1199px){
  .first-screen{
    padding-top: 60px;
    padding-bottom: 60px;
    h1{

      font-size: 80px;
      line-height: 105px;
    }
    .text-first{
      margin-bottom: 70px;
    }
  }
}
@media screen and (max-width: 992px){
  .first-screen{
    padding-top: 40px;
    padding-bottom: 40px;
    h1{

      font-size: 80px;
      line-height: 105px;
    }
    .text-first{
      margin-bottom: 50px;
    }
  }
}
@media screen and (max-width: 767px){
  .first-screen{
    padding-top: 40px;
    padding-bottom: 40px;
    h1{

      font-size: 60px;
      line-height: 85px;
    }
    .text-first{
      margin-bottom: 50px;
    }
    .btn-radius{
      width: 100%;
    }
  }
}
@media screen and (max-width: 575px){
  .first-screen{
    padding-top: 30px;
    padding-bottom: 30px;
    h1{

      font-size: 40px;
      line-height: 65px;
      br{
        display: none;
      }
    }
    .text-first{
      margin-bottom: 50px;
    }
    .btn-radius{
      width: 100%;
    }
  }
}
