.slider-detail{
  position: relative;
  overflow-x: hidden;
  .container-slides-detail{
    h1{
      font-size: 70px;
      line-height: 82px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 50px;
    }
    .slide-detail{
      .btn-radius{
        transition: ease .4s;
        &:hover{
          background-color: #007bff;
        }
      }
      .logo-project{
        img{
          margin-top: -35px;
          width: 186px;
          height: 111px;
          display: block;
        }
      }
      .type-project{
        margin-bottom: 25px;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        color: #0071FF;
      }
      .text-project{
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        padding-right: 20px;
        margin-bottom: 50px;
      }
      .screen-project{
        background-color: #021e36;
        border-radius: 35px;
        padding: 30px;
        margin-bottom: 30px;
        .screen-img{
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 8px;
          img{
            width: 100%;
            height: 100%;
          }
          iframe{
            width: 710px;
            height: 384px;
          }
        }
      }
      .logo-product{
        margin-bottom: 80px;
        display: flex;
        align-items: center;
        div{
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          display: flex;
          text-transform: uppercase;
          color: #808792;
          margin-right: 30px;
        }
      }
      .slide-info-block{
        margin-bottom: 50px;
        h2{
          font-weight: bold;
          font-size: 40px;
          line-height: 28px;
          margin-bottom: 40px;
        }
        ul{
          padding-left: 40px;
          list-style: disc;
          li{
            a{
              transition: ease .4s;
              &:hover{
                color:#0071ff;
              }
            }
          }
        }
        p,li{
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          color: #FFFFFF;
          padding-right: 60px;
          margin-bottom: 25px;
          &.no-list-image{
            list-style: none!important;
          }
          &>p{
            font-weight: bold!important;
          }
          a{
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
            transition: ease .4s;
            &:hover{
              color:#0071ff;
            }
          }
        }

        h3{
          font-style: italic;
          font-weight: bold;
          font-size: 28px;
          line-height: 28px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        &.last{
          a:first-child{
            margin-bottom: 20px;
            font-size: 18px;
            text-align: center;
            transition: ease .4s;
            &:hover{
              color:#0071ff;
            }
          }
        }
      }
      .screen-big-block{
        margin-bottom: 80px;
        margin-top: 20px;
        img{
          display: block;
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
      .btns-block{
        margin-bottom: 100px;
        margin-top: 40px;
        .btn-radius{
          font-size: 16px;
          width: 299px;
          transition: ease .4s;
          &:hover{
            background-color: #0071ff;
          }
        }
      }
    }
  }
  .btn-slider-next{
    z-index: 100;
    cursor: pointer;
    position: fixed;
    width: 180px;
    height: 180px;
    right: -80px;
    top: calc(50% - 90px);
    .container-btn{
      position: relative;
      img{
        position: absolute;
        &.big-circle-btn{
          width: 180px;
          height: 180px;
          left:0px;
          top:0px;
        }
        &.small-circle-btn{
          width: 150px;
          height: 150px;
          left: 15px;
          top: 15px;
        }
        &.arrow{
          width: 38px;
          height: 15px;
          opacity: 1;
          transition: ease .4s;
          left: calc(35% - 19px)!important;
          top: 82.5px!important;
          &.hovered{
            opacity: 0;
          }
        }
      }
    }
    &:hover{
      .container-btn{
        img{
          &.arrow{
            opacity: 0;
            &.hovered{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .btn-slider-prev{
    z-index: 100;
    position: fixed;
    width: 180px;
    height: 180px;
    left: -80px;
    cursor: pointer;
    top: calc(50% - 90px);
    .container-btn{
      position: relative;
      img{
        position: absolute;
        &.big-circle-btn{
          width: 180px;
          height: 180px;
          left:0px;
          top:0px;
        }
        &.small-circle-btn{
          width: 150px;
          height: 150px;
          left: 15px;
          top: 15px;
        }
        &.arrow{
          width: 38px;
          height: 15px;
          opacity: 1;
          transition: ease .4s;
          right: calc(35% - 19px)!important;
          top: 82.5px!important;
          &.hovered{
            opacity: 0;
          }
        }
      }
    }
    &:hover{
      .container-btn{
        img{
          &.arrow{
            opacity: 0;
            &.hovered{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .back-top-block{
    margin: 10px 0 100px;
    .back-top-link{
      padding-left: 60px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #808792;
      transition: ease .4s;
      .icon-back{
        position: relative;
        img{
          position: absolute;
          left:-60px;
          top: calc(50% - 9px);
          width: 35px;
          height: 18px;
          opacity: 1;
          transition: ease .4s;
          &.hovered{
            opacity: 0;
          }
        }
      }
      &:hover{
        color: #ffffff;
        .icon-back{
          img{
            opacity: 0;
            &.hovered{
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .back-bottom-block{
    margin-bottom: 40px;
    .back-bottom-link{
      padding-left: 60px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #808792;
      transition: ease .4s;
      .icon-back{
        position: relative;
        img{
          position: absolute;
          left:-60px;
          top: calc(50% - 9px);
          width: 35px;
          height: 18px;
          opacity: 1;
          transition: ease .4s;
          &.hovered{
            opacity: 0;
          }
        }
      }
      &:hover{
        color: #ffffff;
        .icon-back{
          img{
            opacity: 0;
            &.hovered{
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1659px){
  .slider-detail{
    .btn-slider-next{
      width: 150px;
      height: 150px;
      right: -100px;
      top: calc(50% - 75px);
      .container-btn{
        img{
          &.big-circle-btn{
            width: 150px;
            height: 150px;
          }
          &.small-circle-btn{
            width: 120px;
            height: 120px;
            left: 15px;
            top: 15px;
          }
          &.arrow{
            width: 26px;
            height: 10px;
            left: calc(25% - 13px)!important;
            top: 67.5px!important;
          }
        }
      }
    }
    .btn-slider-prev{
      width: 150px;
      height: 150px;
      left: -100px;
      top: calc(50% - 75px);
      .container-btn{
        img{
          &.big-circle-btn{
            width: 150px;
            height: 150px;
          }
          &.small-circle-btn{
            width: 120px;
            height: 120px;
            left: 15px;
            top: 15px;
          }
          &.arrow{
            width: 26px;
            height: 10px;
            right: calc(25% - 13px)!important;
            top: 67.5px!important;
          }
        }
      }
    }
    .container-slides-detail{
      h1{
        font-size: 50px;
        line-height: 1.15;
        margin-bottom: 20px;
      }
      .slide-detail{
        .link-project{
          margin-bottom: 10px;
        }
        .text-project{
          font-size: 16px;
          line-height: 26px;
          color: #FFFFFF;
          padding-right: 20px;
          margin-bottom: 30px;
        }
        .screen-project{
          .screen-img{
            iframe{
              width: 395px;
              height: 213px;
            }
          }
        }
        .slide-info-block{
          margin-bottom: 50px;
          h2{
            font-weight: bold;
            font-size: 30px;
            line-height: 28px;
            margin-bottom: 20px;
          }
          ul{
            padding-left: 20px;
            list-style: disc;
          }
          p,li{
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #FFFFFF;
            padding-right: 60px;
            margin-bottom: 15px;
          }

          h3{
            font-style: italic;
            font-weight: bold;
            font-size: 22px;
            line-height: 24px;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px){
  .slider-detail{
    .container-slides-detail{
      .slide-detail{
        h1{
          font-size: 38px;
          line-height: 1.15;
          margin-bottom: 20px;
          text-align: center;
          justify-content: center;
        }
        .logo-project{
          img{
            margin-top: -35px;
            display: block;
          }
        }
        .text-project{
          padding-right: 0px;
        }
        .screen-project{
          .screen-img{
            iframe{
              width: 880px;
              height: 475px;
            }
          }
        }
        .btn-radius{
          width: 100%;
          margin-bottom: 40px;
        }
      }
    }
    .back-top-block{
      margin: 10px 0 50px;
    }
  }
}
@media screen and (max-width: 991px){
  .slider-detail{
    .btn-slider-next{
      width: 120px;
      height: 120px;
      right: -80px;
      top: calc(50% - 60px);
      .container-btn{
        img{
          &.big-circle-btn{
            width: 120px;
            height: 120px;
          }
          &.small-circle-btn{
            width: 90px;
            height: 90px;
            left: 15px;
            top: 15px;
          }
          &.arrow{
            width: 18px;
            height: 8px;
            right: calc(25% - 9px)!important;
            top: 55px!important;
          }
        }
      }
    }
    .btn-slider-prev{
      width: 120px;
      height: 120px;
      left: -80px;
      top: calc(50% - 60px);
      .container-btn{
        img{
          &.big-circle-btn{
            width: 120px;
            height: 120px;
          }
          &.small-circle-btn{
            width: 90px;
            height: 90px;
            left: 15px;
            top: 15px;
          }
          &.arrow{
            width: 18px;
            height: 8px;
            right: calc(25% - 9px)!important;
            top: 55px!important;
          }
        }
      }
    }
    .container-slides-detail{
      .slide-detail{
        .logo-project{
          img{
            margin-top: -15px;
          }
        }
        h1{
          font-size: 32px;
        }
        .screen-project{
          .screen-img{
            iframe{
              width: 640px;
              height: 346px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .slider-detail {
    .container-slides-detail {
      .slide-detail {
        .screen-project{
          .screen-img{
            iframe{
              width: 460px;
              height: 248px;
            }
          }
        }
        .logo-project{
          img{
            width: 100px;
            height: 60px;
          }
        }
        .screen-big-block{
          margin-bottom: 40px;
        }
        .btns-block{
          margin-bottom: 100px;
          margin-top: 40px;
          .btn-radius{
            margin-bottom: 20px;
            transition: ease .4s;
            width: 100%;
            &:hover{
              background-color: #0071ff;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .slider-detail {
    .container-slides-detail {
      .slide-detail {
        h1{
          font-size: 26px;
          line-height: 1.15;
          margin-bottom: 20px;
        }

        .screen-project{
          padding: 15px;
          border-radius: 18px;
          .screen-img{
            iframe{
              width: calc(100vw - 50px);
              height: calc((100vw - 50px)* 0.54);
            }
          }
        }
        .logo-project{
          img{
            margin-top: 0px;
            margin-bottom: 10px;
            width: 150px;
            height: 90px;
            display: block;
          }
        }
        .type-project{
          text-align: center;
          justify-content: center;
        }
        .logo-product{
          div{
            font-size: 16px;
          }
          img{
            width: 150px;
            height:40px;
          }
        }
        .btn-radius{
          font-size: 16px;
        }
        .slide-info-block{
          h2{
            font-size: 24px;
          }
          p,li{
            font-size: 14px;
            padding-right: 0;
            line-height: 20px;
          }
          h3{
            font-size: 18px;
          }
        }
      }
    }
  }
}
