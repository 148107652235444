.fancybox-bg{
  background-color: rgba(0, 0, 0, 0.5)!important;
}
.fancybox-content{
  padding: 0!important;
  overflow: unset!important;
  background-color: transparent;
}
  .container-modal{
    width: 934px;
    background-color: #02193a;
    overflow: unset;
    padding: 35px 35px 45px 60px;
    .head-modal{
      position: relative;
      .name-modal{
        font-size: 40px;
        line-height: 47px;
        letter-spacing: -0.015em;
        text-transform: uppercase;
      }
      .btn-close{
        position: absolute;
        right: 0px;
        top:-10px;
        cursor: pointer;
        z-index: 100;
        .icon-close{
          position: relative;
          width: 62px;
          height: 62px;
          overflow: hidden;
          img{
            position: absolute;
            opacity: 1;
            top: -12px;
            left: -12px;
            transition:  ease .4s;
            &.hovered{
              opacity: 0;
            }
          }
        }
        &:hover{
          .icon-close{
            img{
              opacity: 0;
              &.hovered{
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .body-modal{
      margin-top: 20px;
      form{
        display: flex;
        .form-inputs{
          .container-input{
            margin-bottom: 20px;
            .placeholder{
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.015em;
              color: rgba(255, 255, 255, 0.4);
              margin-bottom: 8px;
            }
            input{
              border: 1px solid #0071FF;
              border-radius: 30px;
              background-color: transparent;
              padding: 14px 20px;
              transition: ease .4s;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.015em;
              width: 363px;
              display: block;
              color: #fff;
              &:focus{
                background-color: #0071FF;
              }
            }
          }
          .container-textarea{
            margin-bottom: 20px;
            .placeholder{
              font-size: 16px;
              line-height: 19px;
              letter-spacing: -0.015em;
              color: rgba(255, 255, 255, 0.4);
              margin-bottom: 8px;
            }
            textarea{
              border: 1px solid #0071FF;
              border-radius: 30px;
              padding: 14px 20px;
              width: 100%;
              background-color: transparent;
              resize: none;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.015em;
              color: #fff;
              height: 170px;
              overflow: hidden;
              transition: ease .4s;
              &:focus{
                background-color: #0071FF;
              }
            }
          }
        }
        .form-desc{
          margin-left: 50px;
          margin-top: 25px;
          padding-right: 40px;
        }
      }
    }
    .footer-modal{
      .politics{
        display: flex;
        margin-bottom: 20px;
        label{
          margin-right: 20px;
          width: 20px;
          height: 20px;
          overflow: hidden;
          border: 1px solid #0071FF;
          border-radius: 2px;
          flex-shrink: 0;
          position: relative;
          cursor: pointer;
          display: block;
          &::before{
            content: '';
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            top: 4px;
            left: 4px;
            background-color: #0071FF;
            opacity: 0;
            transition: ease .4s;
          }
          input{
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            left: -20px;
            top: 0;
          }
          &.active{
            &::before{
              opacity: 1;
            }
          }
        }
        .politics-text{
          font-size: 14px;
          line-height: 28px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          width: 421px;
          a{
            color: #0071FF;
          }
        }
      }
      button{
        display: flex;
        border: 1px solid #0071FF;
        box-sizing: border-box;
        border-radius: 30px;
        background-color: transparent;
        transition:  ease .4s;
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        text-align: center;
        letter-spacing: -0.015em;
        text-transform: uppercase;
        width: 299px;
        height: 55px;
        &:hover{
          background-color: #0071FF;
        }
      }
    }
  }
@media screen and (max-width: 1659px){

}
@media screen and (max-width: 1199px){

}
@media screen and (max-width: 992px){
  .container-modal{
    width: 720px;
    padding: 20px 20px 20px 20px;
    .head-modal{
      position: relative;
      .name-modal{
        font-size: 30px;
        line-height: 37px;
      }
      .btn-close{
        position: absolute;
        right: 0px;
        top:-10px;
        cursor: pointer;
        z-index: 100;
        .icon-close{
          width: 48px;
          height: 48px;
          img{
            width: 66px;
            top: -9px;
            left: -9px;
          }
        }
        &:hover{
          .icon-close{
            img{
              opacity: 0;
              &.hovered{
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .body-modal{
      margin-top: 20px;
      form{
        flex-direction: column;
        .form-inputs{
          order:1;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .container-input{
            width: 48%;
            input{
              width: 100%;
              &:focus{
                background-color: #0071FF;
              }
            }
          }
          .container-textarea{
            margin-bottom: 20px;
            width: 100%;
            textarea{
              height: 80px;
            }
          }
        }
        .form-desc{
          margin-left: 0px;
          margin-top: 0px;
          padding-right: 0px;
          margin-bottom: 20px;
        }
      }
    }
    .footer-modal{
      .politics{
        .politics-text{
          width: unset;
          a{
            color: #0071FF;
          }
        }
      }
      button{
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .container-modal{
    width: 540px;
  }
}
@media screen and (max-width: 575px){
  .container-modal{
    width: 100%;

    .head-modal{
      position: relative;
      .name-modal{
        font-size: 22px;
        line-height: 29px;
      }
      .btn-close{
        right: -15px;
        top:-15px;
        .icon-close{
          width: 38px;
          height: 38px;
          img{
            width: 50px;
            top: -5px;
            left: -5px;
          }
        }
        &:hover{
          .icon-close{
            img{
              opacity: 0;
              &.hovered{
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .body-modal{
      margin-top: 10px;
      form{
        flex-direction: column;
        .form-inputs{
          order:1;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .container-input{
            width: 100%;
            margin-bottom: 10px;
            .placeholder{
              font-size: 12px;
              line-height: 15px;
              margin-bottom: 2px;
            }
            input{
              width: 100%;
              padding: 10px 20px;
            }
          }
          .container-textarea{
            margin-bottom: 10px;
            width: 100%;
            .placeholder{
              font-size: 12px;
              margin-bottom: 2px;
            }
            textarea{
              height: 80px;
            }
          }
        }
        .form-desc{
          font-size: 12px;
          margin-left: 0px;
          margin-top: 0px;
          padding-right: 0px;
          margin-bottom: 20px;
        }
      }
    }
    .footer-modal{
      .politics{
        label{
          margin-right: 10px;
        }
        .politics-text{
          width: unset;
          line-height: unset;
          font-size: 12px;
          a{
            color: #0071FF;
          }
        }
      }
      button{
        width: 100%;
        height: 39px;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 400px){
  .container-modal{
    .head-modal{
      .name-modal{
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}
