.partners{
  padding-bottom: 300px;
  h2{
    margin: 30px 0;
  }
  .partners-text{
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.015em;
    padding-right: 50px;
    margin-bottom: 70px;

  }
  .btn-radius{
    padding: 15px 65px;
    transition: ease .4s;
    &:hover{
      background-color: #007bff;
    }
  }
  .container-partners{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 50px;
    .partner{
      display: flex;
      align-items: center;

      margin-right: 50px;
      margin-bottom: 60px;
      img{

      }
      span{
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.015em;
        color: #FFFFFF;
        margin-left: 18px;
      }
      &:nth-child(3){
        margin-right: 40px;
      }
      &:nth-child(4){
        margin-left: 10px;
        margin-right: 0px;
      }
    }
  }
}
@media screen and (max-width: 1659px){
  .partners{
    .partners-text{
      padding-right: 0px;
    }
    .container-partners{
      padding-left: 60px;
      .partner{
        margin-bottom: 45px;
        margin-right: 40px;
        &:nth-child(2){
          margin-right: 0;
        }
        &:nth-child(3){
          margin-right: 80px;
        }
        &:nth-child(4){
          margin-left: 0px;
          margin-right: 80px;
        }
        &:nth-child(5){
          margin-right: 40px;
        }
        &:nth-child(6){
          margin-right: 100px;
        }
      }
    }
  }
}
@media screen and (max-width: 1199px){
  .partners{
    .partners-text{
      padding-right: 0px;
    }
    .container-partners{
      padding-left: 0px;
      .partner{
        margin-bottom: 45px;
        span{
          margin-left: 10px;
          font-size: 20px;
        }
        &:nth-child(1){
          margin-right: 68px;
        }
        &:nth-child(2){
          margin-right: 0;
        }
        &:nth-child(3){
          margin-right: 80px;
        }
        &:nth-child(4){
          margin-left: 0px;
          margin-right:80px;
        }
        &:nth-child(5){
          margin-right: 0px;
        }
      }
    }
  }
}
@media screen and (max-width: 992px){
  .partners{
    .partners-text{
      padding-right: 0px;
      margin-bottom: 33px;
    }
    .btn-radius{
      width: 100%;
    }
    .container-partners{
      padding-left: 0px;
      .partner{
        margin-bottom: 45px;
        span{
          margin-left: 10px;
          font-size: 20px;
        }
        &:nth-child(1){
          margin-right: 20px;
        }
        &:nth-child(2){
          margin-right: 0;
        }
        &:nth-child(3){
          margin-right: 89px;
        }
        &:nth-child(4){
          margin-left: 0px;
          margin-right: 0;
        }
        &:nth-child(5){
          margin-right: 0px;
        }
        &:nth-child(6){
          margin-right: 0px;
        }
        &:nth-child(7){
          margin-right: 23px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .partners{
    padding-bottom: 100px;
    .partners-text{
      padding-right: 0px;
      margin-bottom: 20px;
    }
    .btn-radius{
      width: 100%;
      margin-bottom: 30px;
    }
    .container-partners{
      padding-left: 0px;
      .partner{
        margin-bottom: 45px;
        span{
          margin-left: 10px;
          font-size: 18px;
        }
        &:nth-child(1){
          margin-right: 23px;
        }
        &:nth-child(2){
          margin-right: 0;
        }
        &:nth-child(3){
          margin-right: 20px;
        }
        &:nth-child(4){
          margin-left: 0px;
          margin-right: 15px;
        }
        &:nth-child(5){
          margin-right: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .partners{
    padding-bottom: 50px;
    .partners-text{
      font-size: 16px;
    }
    .container-partners{
      .partner{
        &.big{
          max-width: 100%;
          img{
            width: 290px;
            display: block;
          }
        }
      }
    }
  }
}
