footer{
  .top-footer{
    padding: 50px 0;
    .footer-products-list{
      .head-list{
        font-size: 24px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 25px;
      }
      ul{
        li{
          margin-bottom: 25px;
          padding-right: 20px;
          a{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #FFFFFF;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .blocks-list{
      li{
        margin-bottom: 40px;
        a{
          font-family: inherit;
          font-weight: inherit;
          font-size: 24px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .block-phones-and-call{
      .phone{
        margin-bottom: 42px;
        a{
          font-size: 24px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
          display: block;
          margin-bottom: 15px;
        }
        .title-phone{
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.015em;
          color: #FFFFFF;
        }
      }
      .call-text{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;
        color: #0071FF;
      }
    }
  }
  .bottom-footer{
    .brt{
      padding-top: 40px;
      padding-bottom: 44px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        display: block;
        background-color: rgba(255, 255, 255, 0.2);
        width: calc(100% - 20px);
        height: 1px;
        top: 0;
        left: 10px;
        right: 10px;
      }
      a,p{
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.015em;
        margin-bottom: 0;
        &.center{
          padding-left: 300px;
        }
      }
    }
  }
}
.menu-container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top:0;
  right:-100vw ;
  transition: ease .4s;


  .menu-bg{

    nav{
      z-index: 1005;
      position: absolute;
      right: -240px;
      transition: right ease .4s;
      background-color: #020F25;
      width: 320px;
      padding: 20px 20px 20px 10px;
      height: 100vh;
      ul{
        padding-left: 10px;
        li{
          cursor: pointer;
          font-size: 16px;
          &:hover{
            &>.container-child{
              &>div{
                color:#fff;
              }
              &>.btn-arrow{
                .icon-block{
                  background-color: #fff;
                }
              }
            }
            &>a{
              color: #fff;
            }
            &>.hidden-list{
              li{
                a{
                  color: #fff;
                  font-size: 16px;
                }
                .container-child{
                  div{
                    color: #fff;
                    font-size: 16px;
                  }
                }
                &:hover{
                  &>a{
                    color: #fff;
                  }
                  &>.container-child{
                    &>div{
                      color: #fff;
                    }
                    &>.btn-arrow{
                      .icon-block{
                        background-color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
          &.main-item{
            margin-bottom: 10px;
            &>a, &>.container-child>div{
              font-size: 20px;
            }
          }
          a, .container-child div{
            color: #ffffff;
            transition: ease .4s;
            font-size: 20px;
          }
          &.parent{
            .container-child{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              transition: ease .4s;
              .btn-arrow{
                width: 20px;
                height: 12px;
                position: relative;
                flex-shrink: 0;
                margin-left: 10px;
                margin-top: 3px;
                .icon-block{
                  position: absolute;
                  border-radius: 4px;
                  width: 12px;
                  height: 4px;
                  background-color: #fff;
                  transition: ease .4s;
                  top:4px;
                  &:first-child{
                    left: 1px;
                    transform: rotate(45deg);
                  }
                  &:last-child{
                    right: 1px;
                    transform: rotate(-45deg);
                  }
                }
              }
            }
            &.active{
              &>.container-child{
                &>a{
                  color: #fff!important;
                  margin-bottom: 10px;
                }
                &>div{
                  color: #fff!important;
                }
                &>.btn-arrow{
                  transform: rotate(180deg);
                  .icon-block{
                    background-color: #fff;
                  }
                }
              }
              &>ul{
                height: auto;
                margin: 10px 0;
                li{
                  margin-bottom: 10px;
                  .container-child{
                    div{
                      font-size: 16px;
                    }
                  }
                  a{
                    font-size: 16px;
                  }
                }
              }
            }
            &>ul{
              height: 0;
              overflow: hidden;
              margin-bottom: 0;

            }
          }

        }
        .call-item{
          margin-top: 40px;
          a{
            width: 100%;
          }
        }

      }
    }
  }
  &.active{
    right: 0;
    .menu-bg{
      nav{
        transition: ease .4s;
        right: 0px;
      }
    }
  }
}
@media screen and (max-width: 1659px){
  footer{
    .bottom-footer{
      .brt{
        p{
          &.center{
            padding-left: 80px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1199px){
  footer{
    .bottom-footer{
      .brt{
        p{
          &.center{
            padding-left: 0px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px){
  footer{
    .top-footer{
      padding: 30px 0;
      .footer-products-list{
        .head-list{
          font-size: 20px;
          line-height: 16px;
          margin-bottom: 20px;
        }
        ul{
          li{
            margin-bottom: 20px;
            padding-right: 20px;
            a{
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
      .blocks-list{
        li{
          margin-bottom: 32px;
          a{
            font-size: 20px;
            line-height: 16px;
          }
        }
      }
      .block-phones-and-call{
        .phone{
          margin-bottom: 33px;
          a{
            font-size: 20px;
            line-height: 16px;
          }
          .title-phone{
            font-size: 14px;
            line-height: 20px;
          }
        }
        .call-text{
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .bottom-footer{
      .brt{
        padding: 30px 0 20px;
        p{
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
footer{
  .top-footer{
    padding: 30px 0;
    .block-phones-and-call{
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;

      .phone{
        width: 50%;
        margin-bottom: 33px;
        padding-right: 40px;
        a{
          font-size: 20px;
          line-height: 16px;
        }
        .title-phone{
          font-size: 14px;
          line-height: 20px;
        }
        &:nth-child(2){
          padding-left: 10px;
        }
      }
      .call-text{
        width: 100%;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
}
@media screen and (max-width: 575px){
  footer{
    .top-footer{
      .blocks-list{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a{
          white-space: nowrap;
          text-align: center;
          display: block;
        }
      }
      .footer-products-list{
        .head-list{
          text-align: center;
        }
        ul{
          li{
            padding-right: 0;
            a{
              display: block;
              text-align: center;
            }
          }
        }
      }
      .block-phones-and-call{
        .phone{
          margin-bottom: 42px;
          padding: 0 10px;
          a{
            font-size: 24px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #FFFFFF;
            display: block;
            margin-bottom: 15px;
            text-align: center;
          }
          .title-phone{
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.015em;
            color: #FFFFFF;
            text-align: center;
          }
        }
        .call-text{
          width: 100%;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .menu-container{
    .menu-bg{
      nav{
        width: 260px;
        ul{
          li{
            a{
              font-size: 14px;
            }
            &.call-item{
              .btn-radius.btn-small{
                padding: 13px 10px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 450px){
  footer{
    .top-footer{
      .block-phones-and-call{
        .phone{
          width: 100%;
        }
      }
    }
  }
}
