.projects{
  padding-bottom: 50px;
  h2{
    margin: 30px 0;
  }
  .slider-project-container{
    .control{
      display: flex;
      justify-content: flex-end;
      margin: 0 0 80px;
      .btn-left{
        cursor: pointer;
        margin-right: 30px;
        width: 37px;
        height: 15px;
        .container-icon{
          position: relative;
          img{
            opacity: 1;
            transition: ease .4s;
            width: 37px;
            height: 15px;
            position: absolute;
            left: 0;
            top: 0;
            &.hovered{
              opacity: 0;
            }
          }
        }
        &:hover{
          .container-icon{
            img{
              opacity: 0;
              &.hovered{
                opacity: 1;
              }
            }
          }
        }
      }
      .btn-right{
        margin-left: 30px;
        cursor: pointer;
        width: 37px;
        height: 15px;
        .container-icon{
          position: relative;
          img{
            opacity: 1;
            transition: ease .4s;
            width: 37px;
            height: 15px;
            position: absolute;
            left: 0;
            top: 0;
            &.hovered{
              opacity: 0;
            }
          }
        }
        &:hover{
          .container-icon{
            img{
              opacity: 0;
              &.hovered{
                opacity: 1;
              }
            }
          }
        }
      }
      &.top{
        margin: 0 0 80px;
      }
      &.bottom{
        margin: 80px 0 0;
      }
    }
    .slides{
      .slick-list{
        margin: 0 -35px;
      }
      .slide{
        .project{
          margin: 0 auto 0 auto;
          border: 0.5px solid #777777;
          width: 335px;
          height: 410px;
          .project-img{
            width: 100%;
            height: 169px;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
          .project-info{
            padding: 25px 20px;
            .project-type{
              font-size: 14px;
              line-height: 16px;
              letter-spacing: -0.015em;
              color: rgba(255, 255, 255, 0.6);
              margin-bottom: 15px;
            }
            .project-name{
              font-size: 20px;
              line-height: 23px;
              letter-spacing: -0.015em;
              text-decoration-line: underline;
              color: #FFFFFF;
              display: block;
              margin-bottom: 15px;
              height: 46px;
              overflow: hidden;
            }
            .project-desc{
              font-size: 14px;
              line-height: 20px;
              letter-spacing: -0.015em;
              color: #FFFFFF;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1659px){

}
@media screen and (max-width: 1199px){
  .projects{
    .slider-project-container{
      .control{
        &.top{
          margin: 0 0 40px;
        }
        &.bottom{
          margin: 40px 0 0;
        }
      }
      .slides{
        .slick-list{
          margin: 0 -20px;
        }
        .slide{
          .project{
            width: 290px;
            height: 360px;
            .project-info{
              padding: 20px 15px;
              .project-type{
                margin-bottom: 10px;
              }
              .project-name{
                font-size: 16px;
                margin-bottom: 10px;
              }
              .project-desc{
                line-height: 18px;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px){
  .projects{
    .slider-project-container{
      .slides{
        .slick-list{
          margin: 0 -60px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .projects{
    .slider-project-container{
      .control{
        display: flex;
        justify-content: flex-end;
        .btn-left{
          cursor: pointer;
          margin-right: 30px;
          width: 37px;
          height: 15px;
          .container-icon{
            position: relative;
            img{
              opacity: 1;
              transition: ease .4s;
              width: 37px;
              height: 15px;
              position: absolute;
              left: 0;
              top: 0;
              &.hovered{
                opacity: 0;
              }
            }
          }
          &:hover{
            .container-icon{
              img{
                opacity: 0;
                &.hovered{
                  opacity: 1;
                }
              }
            }
          }
        }
        .btn-right{
          margin-left: 30px;
          cursor: pointer;
          width: 37px;
          height: 15px;
          .container-icon{
            position: relative;
            img{
              opacity: 1;
              transition: ease .4s;
              width: 37px;
              height: 15px;
              position: absolute;
              left: 0;
              top: 0;
              &.hovered{
                opacity: 0;
              }
            }
          }
          &:hover{
            .container-icon{
              img{
                opacity: 0;
                &.hovered{
                  opacity: 1;
                }
              }
            }
          }
        }
        &.top{
          margin: 0 0 30px;
        }
        &.bottom{
          margin: 30px 0 0;
        }
      }
      .slides{
        .slick-list{
          margin: 0 -35px;
        }
        .slide{
          .project{
            width: 245px;
            height: 300px;
            .project-img{
              width: 100%;
              height: 130px;
            }
            .project-info{
              padding: 15px 10px;
              .project-type{
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 10px;
              }
              .project-name{
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
                height: 36px;
              }
              .project-desc{
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px){
  .projects{
    .slider-project-container{
      .slides{
        .slick-list{
          margin: 0 0px;
        }
        .slide{
          .project{
            width: 230px;
            height: 290px;
            .project-info{
              padding: 15px 10px;
              .project-type{
                margin-bottom: 5px;
              }
              .project-name{
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px){
  .projects{
    .slider-project-container{
      .control{
        justify-content: space-between;
        .btn-left{
          margin-right: 0px;
          margin-left: 30px;
        }
        .btn-right{
          margin-right: 30px;
          margin-left: 0;
        }
      }
      .slides{
        .slick-list{
          margin: 0 0px;
        }
        .slide{
          .project{
            width: 100%;
            height: unset;
            .project-img{
              width: 100%;
              height: 50vw;
            }
            .project-info{
              height: 178px;
              padding: 20px 15px;
              .project-type{
                font-size: 14px;
                line-height: 15px;
                margin-bottom: 10px;
              }
              .project-name{
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 15px;
                height: 40px;
              }
              .project-desc{
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
